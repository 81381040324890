'use client';

import React from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useRouter } from 'next/navigation';
const Tutoriais: React.FC = () => {
  const router = useRouter();
  return <>
      <div onClick={() => router.push('/suporte')} className="fixed bottom-[50px] right-4 z-20 flex items-center justify-center w-12 h-12 bg-[#039af8] text-[#FFFFFF] rounded-full cursor-pointer">
        <QuestionCircleFilled className="text-[#FFFFFF] text-2xl" data-sentry-element="QuestionCircleFilled" data-sentry-source-file="Tutoriais.tsx" />
      </div>
    </>;
};
export default Tutoriais;